import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vega et Vega Lite sont un peu à part dans le monde des outils et bibliothèques permettant la visualisation. La visualisation est `}<strong parentName="p">{`spécifiée`}</strong>{` en JSON:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
"data": {
    "values": weather_data},
"mark": "tick",
"encoding": {
    "x": {
    "field": "precipitation", 
    "type": "quantitative"
    }
  }
}`}</code></pre></div>
    <p>{`Se transforme en :`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "233px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.67484662576687%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAABjUlEQVQY02PoWrxfsXbWLveqGTsdmubtca2fsxtEW/QsPWA2aeUhs2XbTyks2XpSY8WOU9pLtp1UOHnhmuzlazflTl28Ln7w1BWpB/fvyR45c1X69MXrkvfv3ZVkCKheVRzZdeB/cMOm/yFAHFC9+r9/1Yo7wbWrjobVrz4QWL0yOLZ5XWFKx4Yqv4oVwS3zdmmv2XVSI65ljWRC61r1z28eS+f1bVKPb1kt9fLpQ1WGpmlrTXI7liWlNS0OK+lZkZLXsSwyu3WRT3nvMr+aCcuCKztn6TVPWmzbNWO5S1n7TL1Z85fqrly9zqB/6myl/ukL1DZsWGc4edZCjelzFyvv2rVTiwEIBIGYkQECRICYG8qWAGJhKJsXiGWhbHYg1oWyWYHYBMqWA2I1BkZOITYFAyeO////M/JLq3OJKepxgWS5+EUEmFnZ1VnY2OWYmFm0GJmYDVlY2VQYGZlAGGyRuLQ8M5DiB7E5ODhBDuFiwAUYGRnZgJQjENsBsScQu0OxA1COB1ktFxfCHACY7YttV3gzFgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "precipitation",
          "title": "precipitation",
          "src": "/static/084620b0f2fdc245b61b553624f7d9d5/a1d58/precipitation.png",
          "srcSet": ["/static/084620b0f2fdc245b61b553624f7d9d5/222b7/precipitation.png 163w", "/static/084620b0f2fdc245b61b553624f7d9d5/a1d58/precipitation.png 233w"],
          "sizes": "(max-width: 233px) 100vw, 233px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`Le billet est à lire sur ObservableHQ: `}<a parentName="p" {...{
        "href": "https://observablehq.com/@alainro/introduction-a-vega-lite"
      }}>{`Introduction à Vega Lite`}</a>{`.`}</p>
    <br />
    <p>{`Et un complément sur les `}<a parentName="p" {...{
        "href": "https://observablehq.com/@alainro/petits-multiples-avec-vega-lite?collection=@alainro/vega-vega-lite"
      }}>{`petits multiples`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      